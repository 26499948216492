import React, { useEffect }  from "react";
import "./about.css";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SocialContact from "../../common/social-contact/index";
import i18n from "../../../i18n";
import { withNamespaces } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

function About({t}) {
  const [alignment, setAlignment] = React.useState(navigator.language.substr(0,2));
  const navigate = useNavigate();
  const language = new URLSearchParams(useLocation().search).get('language');
  
  useEffect(() => {
    if(language && language !== alignment) {
      setAlignment(language)
    } if(!language) {
      setAlignment(alignment)
      navigate(`/?language=${alignment}`)
    }
    i18n.changeLanguage(language)
    return () => {
      // Cleanup code (optional) to be executed when the component unmounts
    };
  }, [alignment, language, navigate]);
  
  const handleChange = (
    event,
    newAlignment,
  ) => {
    if(newAlignment) {
      setAlignment(newAlignment);
      i18n.changeLanguage(newAlignment);
      navigate(`/?language=${newAlignment}`)
    }
  };
  return (
    <div className="about">
      <ToggleButtonGroup
        className="languages"
        color="primary"
        value={alignment}
        exclusive
        size="small"
        onChange={handleChange}
      >
        <ToggleButton value="de">DE</ToggleButton>
        <ToggleButton value="en">EN</ToggleButton>
      </ToggleButtonGroup>
      <div className="about-top">
        <div className="about-info">
          {t('greetings')}
         <br /> <span className="info-name">Nei Li, Ma Ye</span>.
         <br /> {t('personalAssesment')}
        </div>
        <div className="about-photo">
          <img 
            src={require("../../../assets/me.png")}
            className="picture"
            alt=''
          />
        </div>
      </div>
      <SocialContact />
    </div>
  );
}

export default withNamespaces()(About);