export const SocialData=[
    {
        platform:"Telegram",
        icon: require("../../assets/icons/telegram.png"),
        link:"mailto:neili.maye@myit-software.com",
    },
    {
        platform:"Github",
        icon: require("../../assets/icons/github.png"),
        link:"https://github.com/patagan?tab=repositories",
    },
    {
        platform:"LinkedIn",
        icon: "https://cdn-icons-png.flaticon.com/512/145/145807.png",
        link: "https://de.linkedin.com/in/nei-li-ma-ye-79048177",
    },
    {
        platform:"Xing",
        icon: "https://www.sgi-gmbh.com/wp-content/uploads/2018/08/xing-icon-rund-sgi-gmbh.png",
        link:"https://www.xing.com/",
    },
];