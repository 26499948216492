import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "project":"Projects",
      "skills":"Skills",
      "work":"Experience",
      "contact":"Contact",
      "greetings":"Hello There, I am",
      "personalAssesment":"I have a strong passion for building quality software.",
      "contactMe":"Want to get in touch? Contact me on any of the platform",
      "workDone" :[
        {
          "company":"Aequitas Software GmbH",
          "designation":"Senior IT Consultant",
          "dateJoining":"01/2019",
          "dateEnd":"01/2023",
          "companyLogo":"https://media-exp1.licdn.com/dms/image/C4D0BAQGkhc-gyD3SsQ/company-logo_200_200/0/1540894152771?e=2147483647&v=beta&t=CvMrZ_9rEYTL38AFyXFQtcUeYrlR8sMV24NUDZ_M4j0",
          "work":"As a senior consultant, my primary responsibility was to assist our clients in the development of their products by providing them with my expertise in modern technologies. This included going on-site to our clients' locations and working directly with their development teams to identify and implement best practices for their projects.\nIn addition to my client-facing responsibilities, I was also tasked with overseeing an intern project within our own company. This involved developing project plans, assigning tasks, and providing mentorship and guidance to the interns as they worked on the project. I ensured that the project was completed on time, within budget, and to the satisfaction of our internal stakeholders.",
        },
        {
          "company":"Capgemini",
          "designation":"Lead Software Developer",
          "dateJoining":"12/2017",
          "dateEnd":"12/2018",
          "companyLogo":"https://www.pcquest.com/wp-content/uploads/2016/05/capgemini-logo.png",
          "work":"As a Lead Software Developer, my primary responsibility was to assist our clients in the development of their products by providing them with my expertise in modern technologies. This included going on-site to our clients' locations and working directly with their development teams to identify and implement best practices for their projects.  I also played a key role in mentoring and coaching junior team members, sharing my knowledge and expertise with them to help them grow and develop their own skills. I collaborated with our leadership team to identify areas for improvement within our consulting practice and helped to develop and implement strategies to drive growth and improve our service offerings.",
        },
        {
          "company":"Target Software Solution GmbH",
          "designation":"Software Developer",
          "dateJoining":"03/2014",
          "dateEnd":"10/2017",
          "companyLogo":"https://media-exp1.licdn.com/dms/image/C4D0BAQG4QxrJE3cwJA/company-logo_200_200/0/1562769965660?e=2147483647&v=beta&t=EgUiD-C-zy_3SXz3qobmT8qb4evYK-vmPbyfmBN7nlg",
          "work":"As a Software Developer, my main responsibility was to implement an Idea Management product for a SAP partner, which involved designing, developing and deploying the product using modern technologies. I was also tasked with migrating the product from the SAP system to the SAP Cloud, ensuring that the migration was seamless and the product was fully functional in the new environment.\nThroughout the project, I collaborated with a cross-functional team of developers, project managers and other stakeholders to ensure timely and successful delivery of the project. I also stayed up-to-date with the latest trends and advancements in the industry to continuously improve the product and deliver the best possible solution to our clients.",
        },
      ],
      "projectsDone": [
        {
          "company":"Statista",
          "designation":"Fullstack Software Developer",
          "dateJoining":"10/2021",
          "dateEnd":"01/2023",
          "companyLogo":"https://assets.kununu.com/media/prod/profiles/logos/930be69b-06c7-482b-887c-fc6d665cb13b_1_635bd07cb6ead.gif",
          "work":"As part of a complex architecture for a product subscription-based company, I was part of the development team that designed the microservices-based application on the AWS cloud. The project involved automating the deployment of services using modern DevOps technologies, including Docker and AWS services, to ensure seamless scalability and high availability. My team and I designed and implemented a modular architecture that allowed us to break down the monolithic legacy application into smaller, more manageable services.",
        },
        {
          "company":"Kühne & Nagel",
          "designation":"Fullstack Software Developer",
          "dateJoining":"03/2021",
          "dateEnd":"09/2021",
          "companyLogo":"https://www.filderstadt.de/site/Filderstadt-Internet-2019/get/documents/filderstadt/dictionaries/bild/Firme/kn_logos-exception1.JPG",
          "work":"The project aimed to provide users with real-time pricing information for freight shipments from multiple carriers. To achieve this, we used modern technologies for the Frontend and Backend and a microservices architecture, which allows us to break down the system into smaller, more manageable components that can be developed and deployed independently. This will help us to develop a scalable and reliable system that can handle large amounts of traffic and data.",
        },
        {
          "company":"ElbFormat",
          "designation":"Fullstack Software Developer",
          "dateJoining":"08/2020",
          "dateEnd":"11/2020",
          "companyLogo":"https://media.glassdoor.com/sqll/2034534/elbformat-content-solutions-squarelogo-1522312824926.png",
          "work":"This project aimed to create a comprehensive infrastructure that enables test automation and code quality improvement for a set of projects related to a Content Management Platform (CMS). The goal was to develop a test suite that covers all aspects of the CMS platform to ensure it operates flawlessly and improve the quality of the code. Additionally, it was important to implement a Continuous Integration and Delivery (CI/CD) pipeline to automate testing and deployment of code changes. As part of this project, I also provided training to familiarize the development team with a test-driven development approach (TDD) to ensure the quality of the code remains high in the future."
        },
        {
          "company":"Teravolt",
          "designation":"Fullstack Software Developer",
          "dateJoining":"02/2020",
          "dateEnd":"04/2020",
          "companyLogo":"https://www.teravolt.tv/wp-content/uploads/2018/12/teravolt-favicon.png",
          "work":"In diesem Projekt war unser Ziel, eine Microservice-Architektur mithilfe moderner Technologien zu erstellen, um Echtzeit-Video-Streaming für Sportveranstaltungen bereitzustellen, sowie Live-Statistiken des Spiels. Das Hauptziel war es, ein ansprechendes und interaktives Erlebnis für Sportfans zu schaffen, indem wir hochwertige Video-Streams und Spiel-Daten in Echtzeit liefern. \nUnsere Microservices-Architektur ermöglichte es uns, ein effizientes, skalierbares und zuverlässiges System zu erstellen, das große Mengen an Traffic und Daten ohne Leistungseinbußen bewältigen konnte. Wir verwendeten moderne Tools zur Überwachung und Wartung des Systems, einschließlich automatisierter Test- und Bereitstellungspipelines, um ein reibungsloses und unterbrechungsfreies Seherlebnis für Benutzer sicherzustellen.",
        },
        {
          "company":"MSG nexinsure",
          "designation":"Fullstack Software Developer",
          "dateJoining":"01/2019",
          "dateEnd":"08/2019",
          "companyLogo":"https://media-exp1.licdn.com/dms/image/C4D0BAQH57o9Wp9wluQ/company-logo_200_200/0/1577094927074?e=2147483647&v=beta&t=AxkU4uuPt5cQfg0bu81fd_PwFN-Imvj1aDry8Id5eP0",
          "work":"This project involved developing an insurance management product for medical insurance companies, which required a high degree of customization to meet the unique needs of each client. Our team was responsible for writing new functionalities, testing them thoroughly, and integrating them into the product.\nTo achieve this, we followed an agile development methodology that allowed us to work closely with our clients to understand their requirements and customize the product accordingly. We also conducted extensive testing to ensure the product's reliability and security.",
        },
        {
          "company":"Aequitas Software GmbH",
          "designation":"Senior IT Consultant",
          "dateJoining":"01/2019",
          "dateEnd":"01/2023",
          "companyLogo":"https://media-exp1.licdn.com/dms/image/C4D0BAQGkhc-gyD3SsQ/company-logo_200_200/0/1540894152771?e=2147483647&v=beta&t=CvMrZ_9rEYTL38AFyXFQtcUeYrlR8sMV24NUDZ_M4j0",
          "work":"Development of a Profile Manager web application using microservices architecture and modern technologies. My primary responsibility was to design and develop microservices that could handle specific functions of the profile manager application. This included designing and developing APIs, writing clean and maintainable code, and implementing unit and integration tests.",
        },
        {
          "company":"Vattenfall",
          "designation":"Fullstack Software Developer",
          "dateJoining":"05/2023",
          "dateEnd":"12/2023",
          "companyLogo":"https://pbs.twimg.com/profile_images/1102588077946609667/h9XBmrzd_400x400.png",
          "work":"As a full-stack developer, I spearheaded the implementation of a modern microservices architecture with an event-driven approach for a complex energy trading application. Utilizing technologies such as Java, Spring Boot, Vue.js, Kubernetes, and Azure Cloud, my focus included creating an event-driven system for enhanced scalability and real-time responses. I also contributed to the frontend design, ensuring a seamless end-to-end solution and gaining valuable insights into both backend and frontend development in intricate domains like energy trading.",
        },
      ]
    }
  },
  de: {
    translation: {
      "Welcome to React":"test",
      "project":"Projekts",
      "skills":"Skills",
      "work":"Arbeitserfahrung",
      "contact":"Contakt",
      "greetings":"Hallo, Ich bin",
      "personalAssesment":"Ich habe eine starke Leidenschaft für die Entwicklung hochwertiger Software.",
      "contactMe":"Sie möchten Kontakt aufnehmen? Kontaktieren Sie mich auf einer der Plattformen",
      "workDone" :[
        {
          "company":"Aequitas Software GmbH",
          "designation":"Senior IT Consultant",
          "dateJoining":"01/2019",
          "dateEnd":"01/2023",
          "companyLogo":"https://media-exp1.licdn.com/dms/image/C4D0BAQGkhc-gyD3SsQ/company-logo_200_200/0/1540894152771?e=2147483647&v=beta&t=CvMrZ_9rEYTL38AFyXFQtcUeYrlR8sMV24NUDZ_M4j0",
          "work":"Als Senior-Berater lag meine Hauptverantwortung darin, unseren Kunden bei der Entwicklung ihrer Produkte zu helfen, indem ich ihnen meine Expertise in modernen Technologien zur Verfügung stellte. Dazu gehörte der Einsatz vor Ort bei unseren Kunden und die direkte Zusammenarbeit mit ihren Entwicklerteams, um Best Practices für ihre Projekte zu identifizieren und umzusetzen.\n Zusätzlich zu meinen Kundenverantwortlichkeiten war ich auch dafür zuständig, ein Praktikumsprojekt innerhalb unseres eigenen Unternehmens zu betreuen. Dies umfasste die Entwicklung von Projektplänen, die Zuweisung von Aufgaben sowie die Bereitstellung von Mentoring und Anleitung für die Praktikanten während ihrer Arbeit an dem Projekt. Ich sorgte dafür, dass das Projekt termingerecht, innerhalb des Budgets und zur Zufriedenheit unserer internen Stakeholder abgeschlossen wurde.",
        },
        {
          "company":"Capgemini",
          "designation":"Lead Softwareentwickler",
          "dateJoining":"12/2017",
          "dateEnd":"12/2018",
          "companyLogo":"https://www.pcquest.com/wp-content/uploads/2016/05/capgemini-logo.png",
          "work":"Als Leitender Softwareentwickler lag meine Hauptverantwortung darin, unseren Kunden bei der Entwicklung ihrer Produkte zu helfen, indem ich ihnen meine Expertise in modernen Technologien zur Verfügung stellte. Dies umfasste das Vor-Ort-Besuchen unserer Kunden und das direkte Arbeiten mit ihren Entwicklungsteams, um Best Practices für ihre Projekte zu identifizieren und umzusetzen. Ich spielte auch eine wichtige Rolle bei der Betreuung und Unterstützung von junior Teammitgliedern, indem ich mein Wissen und meine Expertise mit ihnen teilte, um ihnen bei ihrem Wachstum und der Entwicklung ihrer eigenen Fähigkeiten zu helfen. Ich arbeitete eng mit unserem Führungsteam zusammen, um Bereiche zur Verbesserung innerhalb unserer Beratungspraxis zu identifizieren und half bei der Entwicklung und Umsetzung von Strategien zur Förderung des Wachstums und zur Verbesserung unserer Serviceangebote.",
        },
        {
          "company":"Target Software Solution GmbH",
          "designation":"Softwareentwickler",
          "dateJoining":"03/2014",
          "dateEnd":"10/2017",
          "companyLogo":"https://media-exp1.licdn.com/dms/image/C4D0BAQG4QxrJE3cwJA/company-logo_200_200/0/1562769965660?e=2147483647&v=beta&t=EgUiD-C-zy_3SXz3qobmT8qb4evYK-vmPbyfmBN7nlg",
          "work":"Als Softwareentwickler lag meine Hauptverantwortung in der Implementierung eines Idea-Management-Produkts für einen SAP-Partner, was die Konzeption, Entwicklung und Bereitstellung des Produkts unter Verwendung moderner Technologien umfasste. Ich war auch damit beauftragt, das Produkt vom SAP-System in die SAP-Cloud zu migrieren und sicherzustellen, dass die Migration nahtlos verläuft und das Produkt in der neuen Umgebung voll funktionsfähig ist.\nWährend des Projekts arbeitete ich eng mit einem interdisziplinären Team aus Entwicklern, Projektmanagern und anderen Stakeholdern zusammen, um eine zeitnahe und erfolgreiche Lieferung des Projekts sicherzustellen. Außerdem blieb ich auf dem neuesten Stand der Trends und Fortschritte in der Branche, um das Produkt kontinuierlich zu verbessern und unseren Kunden die bestmögliche Lösung zu bieten.",
        },
      ],
      "projectsDone": [
        {
          "company":"Statista",
          "designation":"Fullstack Softwareentwickler",
          "dateJoining":"10/2021",
          "dateEnd":"01/2023",
          "companyLogo":"https://assets.kununu.com/media/prod/profiles/logos/930be69b-06c7-482b-887c-fc6d665cb13b_1_635bd07cb6ead.gif",
          "work":"Als Teil einer komplexen Architektur für ein Unternehmen mit einem auf Abonnement basierenden Produkt, war ich Teil des Entwicklungsteams, das die auf Microservices basierende Anwendung auf der AWS-Cloud entworfen hat. Das Projekt beinhaltete die Automatisierung der Bereitstellung von Services mithilfe moderner DevOps-Technologien, einschließlich Docker und AWS-Services, um eine nahtlose Skalierbarkeit und hohe Verfügbarkeit sicherzustellen. Mein Team und ich haben eine modulare Architektur entworfen und umgesetzt, die es uns ermöglichte, die monolithische Legacy-Anwendung in kleinere, handhabbarere Services aufzuteilen.",
        },
        {
          "company":"Kühne & Nagel",
          "designation":"Fullstack Softwareentwickler",
          "dateJoining":"03/2021",
          "dateEnd":"09/2021",
          "companyLogo":"https://www.filderstadt.de/site/Filderstadt-Internet-2019/get/documents/filderstadt/dictionaries/bild/Firme/kn_logos-exception1.JPG",
          "work":"Unser Projekt zielt darauf ab, den Benutzern Echtzeit-Preisinformationen für Frachtsendungen von mehreren Spediteuren bereitzustellen. Um dies zu erreichen, haben wir moderne Technologien für das Front- und Backend sowie eine Microservices-Architektur eingesetzt. Diese ermöglicht es uns, das System in kleinere, leichter zu verwaltende Komponenten zu zerlegen, die unabhängig voneinander entwickelt und bereitgestellt werden können. Dadurch können wir ein skalierbares und zuverlässiges System entwickeln, das großen Datenverkehr und großen Datenmengen standhalten kann.",
        },
        {
          "company":"ElbFormat",
          "designation":"Fullstack Softwareentwickler",
          "dateJoining":"08/2020",
          "dateEnd":"11/2020",
          "companyLogo":"https://media.glassdoor.com/sqll/2034534/elbformat-content-solutions-squarelogo-1522312824926.png",
          "work":"In diesem Projekt ging es darum, eine umfassende Infrastruktur zu schaffen, die die Automatisierung von Tests sowie die Verbesserung der Code-Qualität für eine Reihe von Projekten im Zusammenhang mit einer Content Management-Plattform (CMS) ermöglicht. Das Ziel bestand darin, eine Testsuite zu entwickeln, die alle Aspekte der CMS-Plattform abdeckt, um sicherzustellen, dass sie fehlerfrei funktioniert und die Qualität des Codes zu verbessern. Darüber hinaus war es auch wichtig, eine Continuous-Integration- und Delivery-(CI/CD)-Pipeline zu implementieren, um Tests und Bereitstellung von Code-Änderungen zu automatisieren. Im Rahmen dieses Projekts habe ich auch Schulungen angeboten, um das Entwicklungsteam mit einem testgetriebenen Entwicklungsansatz (TDD) vertraut zu machen, um sicherzustellen, dass die Qualität des Codes auch in Zukunft hoch bleibt.",
        },
        {
          "company":"Teravolt",
          "designation":"Fullstack Softwareentwickler",
          "dateJoining":"02/2020",
          "dateEnd":"04/2020",
          "companyLogo":"https://www.teravolt.tv/wp-content/uploads/2018/12/teravolt-favicon.png",
          "work":"In this project, our goal was to create a microservice architecture using modern technologies to provide real-time video streaming for sport events, along with live statistics of the game. The primary objective was to create an engaging and interactive experience for sports fans by delivering high-quality video streams and game data in real-time. \nOur microservices architecture enabled us to create an efficient, scalable, and reliable system that could handle large volumes of traffic and data without any degradation in performance. We used modern tools for monitoring and maintaining the system, including automated testing and deployment pipelines, to ensure a smooth and uninterrupted viewing experience for users.",
        },
        {
          "company":"MSG nexinsure",
          "designation":"Fullstack Softwareentwickler",
          "dateJoining":"01/2019",
          "dateEnd":"08/2019",
          "companyLogo":"https://media-exp1.licdn.com/dms/image/C4D0BAQH57o9Wp9wluQ/company-logo_200_200/0/1577094927074?e=2147483647&v=beta&t=AxkU4uuPt5cQfg0bu81fd_PwFN-Imvj1aDry8Id5eP0",
          "work":"Dieses Projekt umfasste die Entwicklung eines Versicherungsverwaltungsprodukts für Krankenversicherungsunternehmen, das eine hohe Anpassung an die individuellen Bedürfnisse jedes Kunden erforderte. Unser Team war dafür verantwortlich, neue Funktionalitäten zu schreiben, sie gründlich zu testen und in das Produkt zu integrieren.\nUm dies zu erreichen, folgten wir einer agilen Entwicklungs-Methodologie, die es uns ermöglichte, eng mit unseren Kunden zusammenzuarbeiten, um ihre Anforderungen zu verstehen und das Produkt entsprechend anzupassen. Wir führten auch umfangreiche Tests durch, um die Zuverlässigkeit und Sicherheit des Produkts zu gewährleisten.",
        },
        {
          "company":"Aequitas Software GmbH",
          "designation":"Senior IT Consultant",
          "dateJoining":"01/2019",
          "dateEnd":"01/2023",
          "companyLogo":"https://media-exp1.licdn.com/dms/image/C4D0BAQGkhc-gyD3SsQ/company-logo_200_200/0/1540894152771?e=2147483647&v=beta&t=CvMrZ_9rEYTL38AFyXFQtcUeYrlR8sMV24NUDZ_M4j0",
          "work":"Entwicklung einer Webanwendung für das Profilmanagement unter Verwendung der Microservices-Architektur und moderner Technologien. Meine Hauptverantwortung bestand darin, Microservices zu entwerfen und zu entwickeln, die spezifische Funktionen der Profilmanager-Anwendung verarbeiten können. Dazu gehörten das Entwerfen und Entwickeln von APIs, das Schreiben von sauberem und wartbarem Code sowie die Implementierung von Einheits- und Integrationstests.",
        },
        {
          "company":"Vattenfall",
          "designation":"Fullstack Software Developer",
          "dateJoining":"05/2023",
          "dateEnd":"12/2023",
          "companyLogo":"https://pbs.twimg.com/profile_images/1102588077946609667/h9XBmrzd_400x400.png",
          "work":"Als Full-Stack-Entwickler habe ich die Umsetzung einer modernen Microservices-Architektur mit einem ereignisgesteuerten Ansatz für eine komplexe Energiehandelsanwendung maßgeblich vorangetrieben. Unter Verwendung von Technologien wie Java, Spring Boot, Vue.js, Kubernetes und Azure Cloud lag mein Fokus auf der Schaffung eines ereignisgesteuerten Systems zur Verbesserung von Skalierbarkeit und Echtzeitreaktionen. Zudem habe ich zum Design des Frontends beigetragen, um eine nahtlose End-to-End-Lösung zu gewährleisten. Diese Erfahrung vertiefte mein Verständnis für Backend- und Frontend-Entwicklung und betonte die Bedeutung einer gut orchestrierten Architektur für robuste Anwendungen in komplexen Domänen wie dem Energiehandel.",
        },
      ]
    }
  }
};

i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;