import React, { Component } from "react";
import "./header.css";
import Mobile from "./mobile";
import Web from "./web/index";

import { withNamespaces } from 'react-i18next';


class Header extends Component {
  constructor() {
    super()
    this.setIsOpen = this.setIsOpen.bind(this);
    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {
    window.onscroll = function() {myFunction()};

    var header = document.getElementById("idHeader");

    function myFunction() {
      if (window.pageYOffset > 30) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
  }

  setIsOpen(isOpen) {
    this.setState({
      isOpen: isOpen
    })
  }

  render() {
    return (
      <div id="idHeader" className="header">
        <div className="logo">Myit-Software</div>
        <div className="menu">
          <div className="web-menu">
            <Web />
          </div>
          <div className="mobile-menu">
            <div onClick={()=>this.setIsOpen(!this.state.isOpen)}>
              <i className="fi-rr-apps menu-icon"></i>
            </div>
            {this.state.isOpen && <Mobile isOpen={this.state.isOpen} setIsOpen={this.setIsOpen} />}
          </div>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Header);