import React from "react";
import "./work-card.css";
import { withNamespaces } from 'react-i18next';
import { Fade } from "react-awesome-reveal";

function WorkCard ({ item, t }) {
  return (
    <Fade direction="up" duration={500} triggerOnce className="work-card">
      <div className="work-info">
        <img src={t(item.companyLogo)} className="work-logo" alt='' />
        <h2>{t(item.company)}</h2>
        <label className="company-name">{t(item.designation)}</label>
        <div className="work-desc">
          <p>{t(item.work)}</p>
        </div>
        <div className="project-tags">
          {item.tags.map((tag, index)=> {
            return <label key={index} className="tag">{tag}</label>;
          })}
        </div>
      </div>
    </Fade>
  );
}

export default withNamespaces()(WorkCard);;