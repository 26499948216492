export const WorkData = [
  {
    company: "projectsDone.6.company" ,
    designation: "projectsDone.6.designation",
    dateJoining: "projectsDone.6.dateJoining",
    dateEnd: "projectsDone.6.dateEnd",
    companyLogo:"projectsDone.6.companyLogo",
    work:"projectsDone.6.work",
    tags:["Vue.js", "Javascript", "HTML/CSS", "Java", "Spring Boot", "Azure Cloud", "Docker", "Kubernetes", "Git", "REST", "MongoDB", "SQL", "TimeseriesDB", "Kafka", "Shell", "Maven", "Microservices"],
  },
  {
    company: "projectsDone.0.company" ,
    designation: "projectsDone.0.designation",
    dateJoining: "projectsDone.0.dateJoining",
    dateEnd: "projectsDone.0.dateEnd",
    companyLogo:"projectsDone.0.companyLogo",
    work:"projectsDone.0.work",
    tags:["Vue.js", "Javascript", "HTML/CSS", "Java", "Spring Boot", "AWS", "Docker", "Camunda", "Github", "GraphQL", "REST", "SQL", "Liquibase", "Shell", "Maven", "Gradle", "DataDog", "Microservices"],
  },
  {
    company: "projectsDone.1.company" ,
    designation: "projectsDone.1.designation",
    dateJoining: "projectsDone.1.dateJoining",
    dateEnd: "projectsDone.1.dateEnd",
    companyLogo:"projectsDone.1.companyLogo",
    work:"projectsDone.1.work",
    tags:["Angular", "Javascript", "HTML/CSS", "Java", "Spring Boot", "Bitbucket", "SQL", "Gradle", "Microservices"],
  },
  {
    company: "projectsDone.2.company" ,
    designation: "projectsDone.2.designation",
    dateJoining: "projectsDone.2.dateJoining",
    dateEnd: "projectsDone.2.dateEnd",
    companyLogo:"projectsDone.2.companyLogo",
    work:"projectsDone.2.work",
    tags:["Angular", "Javascript", "HTML/CSS", "Java", "Spring Boot", "Docker", "Jest", "Jenkins", "Github", "Microservices"],
  },
  {
    company: "projectsDone.3.company" ,
    designation: "projectsDone.3.designation",
    dateJoining: "projectsDone.3.dateJoining",
    dateEnd: "projectsDone.3.dateEnd",
    companyLogo:"projectsDone.3.companyLogo",
    work:"projectsDone.3.work",
    tags:["Java", "Dropwizard", "REST", "WebSockets", "SQL", "Redis", "GitLab", "Docker", "Kubernetes", "Microservices", "RabbitMQ", "Prometheus"],
  },
  {
    company: "projectsDone.4.company" ,
    designation: "projectsDone.4.designation",
    dateJoining: "projectsDone.4.dateJoining",
    dateEnd: "projectsDone.4.dateEnd",
    companyLogo:"projectsDone.4.companyLogo",
    work:"projectsDone.4.work",
    tags:["Java", "JFacets", "SQL", "Maven"],
  },
  {
    company: "projectsDone.5.company" ,
    designation: "projectsDone.5.designation",
    dateJoining: "projectsDone.5.dateJoining",
    dateEnd: "projectsDone.5.dateEnd",
    companyLogo:"projectsDone.5.companyLogo",
    work:"projectsDone.5.work",
    tags:["Vue.js", "Javascript", "HTML/CSS", "Java", "Spring Boot", "Bitbucket", "Jenkins", "Docker", "Camunda", "Github", "REST", "SQL", "Shell", "Gradle", "Microservices"],
  }
]