import React from "react";
import "./project-card.css";
import { withNamespaces } from 'react-i18next';

import { Fade } from "react-awesome-reveal";

function ProjectCard({ key, project,t }) {
  return (
    <Fade direction="up" duration={500} triggerOnce  className="project-card">
      <div className="project-info">
        <label className="project-title">{t(project.title)}</label>
        <div>
          <label className="company-name">{t(project.designation)}</label>
        </div>
        <div className="work-dates">
          <label>{t(project.dateJoining)}</label>-<label>{t(project.dateEnd)}</label>
        </div>
        <p>{t(project.about)}</p>
        <div className="project-tags">
          {project.tags.map((tag, index)=> {
            return <label  key={index} className="tag">{tag}</label>;
          })}
        </div>
      </div>
    </Fade>
  );
  
        //<img src={t(project.image)} className="project-photo" />
}

export default withNamespaces()(ProjectCard);