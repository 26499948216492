import React from "react";
import Separator from "../../common/separator";
import { ProjectData } from "../../data/projects";
import ProjectCard from "./project-card";
import "./projects.css";
import { withNamespaces } from 'react-i18next';
function Projects({t}) {
  const data = ProjectData;
  return (
    <div className="projects">
      <Separator />
      <label className="section-title">{t('work')}</label>
      <div>
        {data.map((project, index) => {
          return <ProjectCard key={index} project={project} />;
        })}
      </div>
    </div>
  );
}

export default withNamespaces()(Projects);