import React from "react";
import Home from "./components/home/index";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return <BrowserRouter>
  <Routes>
    <Route path="/" element={<Home />} >
    </Route>
  </Routes>
</BrowserRouter>
}

export default  App;
