import React, { Component } from "react";
import './mobile.css'

import i18n from "../../../i18n";

import { withNamespaces } from 'react-i18next';

class Mobile extends Component {
  constructor(props) {
    super(props)
    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.state = {
      checked: false,
      language: {
        false: "de",
        true: "en"
      }
    }
  }
  toggleLanguage() {
    this.setState({
      checked: !this.state.checked
    });
    i18n.changeLanguage(this.state.language[!this.state.checked]);
  }
  
  render() {
    const { t } = this.props;
    return (
      <div className="mobile">
        <div className="close-icon" onClick={() => this.props.setIsOpen(!this.props.isOpen)}>
          <i className="fi-rr-cross-circle"></i>
        </div>
        <div className="mobile-option">
            <a href="#projects" onClick={() => this.props.setIsOpen(!this.props.isOpen)}>
              <i className="fi-rr-edit-alt option-icon"></i>{t('work')}
            </a>
        </div>
        <div className="mobile-option">
            <a href="#skills" onClick={() => this.props.setIsOpen(!this.props.isOpen)}>
              <i className="fi-rr-laptop option-icon"></i>{t('skills')}
            </a>
        </div>
        <div className="mobile-option">
            <a href="#work" onClick={() => this.props.setIsOpen(!this.props.isOpen)}>
              <i className="fi-rr-briefcase option-icon"></i>{t('project')}
            </a>
        </div>
        <div className="mobile-option">
            <a href="#contact" onClick={() => this.props.setIsOpen(!this.props.isOpen)}>
              <i className="fi fi-rr-user option-icon"></i>{t('contact')}
            </a>
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Mobile);