export const SkillsData = [
  {
    type: "Languages",
    list: [
      {
        name: "Java"
      },
      {
        name: "JavaScript"
      },
      {
        name: "Typescript"
      },
      {
        name: "HTML"
      },
      {
        name: "CSS"
      },
      {
        name: "SQL"
      },
      {
        name: "GraphQL"
      }
    ],
  },
  {
    type: "Frameworks",
    list: [
      {
        name: "Spring"
      },
      {
        name: "Dropwizard"
      },
      {
        name: "React"
      },
      {
        name: "Angular"
      },
      {
        name: "Vuejs"
      },
      {
        name: "Angular"
      },
      {
        name: "Junit"
      },
      {
        name: "Jest"
      }
    ],
  },
  {
    type: "Tools",
    list: [
      {
        name: "Git",
      },
      {
        name: "Jira"
      },
      {
        name: "Jenkins"
      },
      {
        name: "Redis"
      },
      {
        name: "Hibernate"
      },
      {
        name: "Maven"
      },
      {
        name: "Gradle"
      },
    ],
  },
  {
    type: "Platforms",
    list: [
      {
        name: "AWS"
      },
      {
        name: "Camunda"
      },
      {
        name: "SAP Cloud"
      },
      {
        name: "Docker"
      },

    ],
  },
];