import React, { Component } from "react";
import './web.css';

import i18n from "../../../i18n";

import { withNamespaces } from 'react-i18next';

class Web extends Component {
  constructor() {
    super()
    this.toggleLanguage = this.toggleLanguage.bind(this);
    this.state = {
      checked: false,
      language: {
        false: "de",
        true: "en"
      }
    }
  }
  toggleLanguage() {
    this.setState({
      checked: !this.state.checked
    });
    i18n.changeLanguage(this.state.language[!this.state.checked]);
  }
  
  render() {
    
    const { t } = this.props;
    return (
      <div className="web">
        <div className="web-option">
            <a href="#projects">
            <i className="fi-rr-edit-alt option-icon"></i>{t('work')}
            </a>
        </div>
        <div className="web-option">
            <a href="#skills">
            <i className="fi-rr-laptop option-icon"></i>{t('skills')}
            </a>
        </div>
        <div className="web-option">
            <a href="#work">
            <i className="fi-rr-edit-alt option-icon"></i>{t('project')}
            </a>
        </div>
        <div className="web-option">
            <a href="#contact">
            <i className="fi fi-rr-user option-icon"></i>{t('contact')}
            </a>
        </div>
        <div >
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Web);