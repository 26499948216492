export const ProjectData=[
  {
      id:1,
      title:"workDone.0.company",
      designation: "workDone.0.designation",
      dateJoining: "workDone.0.dateJoining",
      dateEnd: "workDone.0.dateEnd",
      about: "workDone.0.work",
      tags:["Vue.js", "Reactjs", "Angular", "Javascript", "HTML/CSS", "Java", "Spring Boot", "Bitbucket", "Jenkins", "AWS", "Docker"],
      image:"workDone.0.companyLogo",
  },
  {
      id:1,
      title:"workDone.1.company",
      designation: "workDone.1.designation",
      dateJoining: "workDone.1.dateJoining",
      dateEnd: "workDone.1.dateEnd",
      about: "workDone.1.work",
      tags:["Java","Angular", "Hibernate", "JPA", "SQL", "Maven", "SVN", "BitBucket"],
      image:"workDone.1.companyLogo",
  },
  
  {
      id:1,
      title:"workDone.2.company",
      designation: "workDone.2.designation",
      dateJoining: "workDone.2.dateJoining",
      dateEnd: "workDone.2.dateEnd",
      about: "workDone.2.work",
      tags:["SAPUI5", "Javascript", "HTML/CSS", "SAP Cloud Platform", "Java", "Java EE", "Selenium", "ABAP"],
      image:"workDone.2.companyLogo",
},
];