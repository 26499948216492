import React, { Component } from "react";
import "./work.css";
import Separator from "../../common/separator";
import {WorkData} from "../../data/work";
import WorkCard from "./work-card";
import { withNamespaces } from 'react-i18next';

class Work extends Component {
  render() {
    const data = WorkData;
    const { t } = this.props;
    return (
      <div className="work">
        <Separator />
        <label className="section-title">{t('project')}</label>
        <div className="work-list">
          {data.map((item, index) => {
            return <WorkCard key={index} item={item} />;
          })}
        </div>
      </div>
    );  
  }
}
export default withNamespaces()(Work);