import React, { Component } from "react";
import "./body.css";
import About from "./about/index";
import Projects from "./projects/index";
import Skills from "./skills/index";
import Work from "./work/index";
import Contact from "./contact/index";

class Body extends Component {
  constructor() {
    super();
    this.state = {
      navigation:  "navigation-hide"
    }
    this.handleScroll = this.handleScroll.bind(this);
    this.toTheTop = this.toTheTop.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    let position = window.pageYOffset;
    this.setState({navigation: 'navigation popout'});
    if (position < 200) {
      this.setState({navigation: 'navigation navigation-hide'});
    }
      
  }

  toTheTop() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      
    //<BrowserRouter>
    //  <Routes>
    //    <Route path="/about" element={ <About/> } />
    //    <Route path="/project" element={ <Projects/> } />
    //    <Route path="/skills" element={ <Skills/> } />
    //    <Route path="/work" element={ <Work/> } />
    //    <Route path="/contact" element={ <Contact/> } />
    //  </Routes>
    //</BrowserRouter>
      <div className="body">
        <section id="about">
          <About />
        </section>
        <section id="projects">
          <Projects />
        </section>
        <section id="skills">
          <Skills />
        </section>
        <section id="work">
          <Work />
        </section>
        <section id="contact">
          <Contact />
        </section>
      </div>
    );

    
    //<Fab className={this.state.navigation}  color="primary" onClick={this.toTheTop}>
    //<NavigationIcon className="icon"/>
    //</Fab>
  }
}

export default Body;